$orange: #ffb100;

$grey1: #414141;
$grey2: #656565;
$grey2a: #707070;
$grey3: #bcbcbc;
$grey4: #ededed;
$grey5: #f7f7f7;

$orgbrain-dark: #37474f;
$orgbrain-blue: #0097a7;
$orgbrain-ligthblue: #c2e6ea;
$orgbrain-gray-background: #eef1f3;

$orgbrain-gray: #607d8b;

$draft-color: #0097a7;
$planned-color: #849dbc;
$annual-plan-color: #4d6bbe;
$called-color: #358abf;
$active-color: #8b5f98;
$requires-signatures-color: #c73c11;
$requires-minutes-color: #f57c00;
$completed-color: #48964c;

.orange {
  color: #ffb100;
}

.grey1 {
  color: $grey1;
}

.grey2 {
  color: $grey2;
}

.grey2a {
  color: $grey2a;
}

.grey2 {
  color: $grey2;
}

.grey3 {
  color: $grey3;
}

.grey4 {
  color: $grey4;
}

.grey5 {
  color: $grey5;
}

.backgrey1 {
  background-color: $grey1;
}

.backgrey2 {
  background-color: $grey2;
}

.backgrey2 {
  background-color: $grey2;
}

.backgrey3 {
  background-color: $grey3;
}

.backgrey4 {
  background-color: $grey4;
}

.backgrey5 {
  background-color: $grey5;
}

.orgbrain-blue-color {
  color: $orgbrain-blue;
}
.orgbrain-orange-color {
  color: $orange;
}
.orgbrain-gray-color {
  color: #546e7a;
}
.orgbrain-border-color {
  color: #cfd8dc;
}
.orgbrain-border {
  border: 1px solid #cfd8dc;
}
.orgbrain-border-bottom {
  border-bottom: 1px solid #cfd8dc;
}
.orgbrain-border-top {
  border-top: 1px solid #cfd8dc;
}
.orgbrain-border-left {
  border-left: 1px solid #cfd8dc;
}
.orgbrain-border-right {
  border-right: 1px solid #cfd8dc;
}
.orgbrain-bg-light-gray {
  background-color: #eef1f3;
}
.orgbrain-bg-blue {
  background-color: $draft-color;
}

$maincolor: rgb(23, 153, 240);
$textmaincolor1: $maincolor;
$backgroundmaincolor: $maincolor;

.maincolor {
  color: $maincolor;
  border-color: $maincolor;
}

.white {
  color: white;
  border-color: white;
}

.backgroundmaincolor {
  background-color: $maincolor;
}

//    ---------- CATEGORY COLOURS ----------

mat-basic-chip mat-icon {
  font-size: 18px;
  height: 18px;
  width: 18px;
}

.meeting-category {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 2px 20px 2px 0px;
  border-radius: 8px;
  font-size: 13px;
  height: 24px;
  line-height: normal;
  @media (max-width: 767px) {
    font-size: 12px;
  }
  .mat-icon {
    margin-right: 10px;
    margin-left: 7px;
    height: 13px !important;
  }
}

.mat-chip.mat-standard-chip,
.mat-mdc-standard-chip,
.meeting-category {
  color: white;
  &.DRAFT {
    background-color: $draft-color !important;
    --mdc-chip-label-text-color: white !important;
  }

  &.PLANNED {
    background-color: $planned-color !important;
    --mdc-chip-label-text-color: white !important;
  }

  &.ANNUAL_PLAN {
    background-color: $annual-plan-color !important;
    --mdc-chip-label-text-color: white !important;
  }

  &.CALLED {
    background-color: $called-color !important;
    --mdc-chip-label-text-color: white !important;
  }

  &.ACTIVE {
    background-color: $active-color !important;
    --mdc-chip-label-text-color: white !important;
  }

  &.REQUIRES_MINUTES {
    background-color: $requires-minutes-color !important;
    --mdc-chip-label-text-color: white !important;
  }

  &.REQUIRES_SIGNATURES {
    background-color: $requires-signatures-color !important;
    --mdc-chip-label-text-color: white !important;
  }

  &.REQUIRES_DISCUSSION {
    background-color: $requires-minutes-color !important;
    --mdc-chip-label-text-color: white !important;
  }

  &.COMPLETED {
    background-color: $completed-color !important;
    --mdc-chip-label-text-color: white !important;
  }

  &.CANCELLED {
    background-color: #c73c11 !important;
    --mdc-chip-label-text-color: white !important;
  }
}
